import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import Home from '../views/Home.vue'
const UserTrackingAct = () => import('@/views/UserTrackingAct.vue')
const UserTrackingResult = () => import('@/views/UserTrackingResult.vue')
const CreateStore = () => import('@/views/CreateStore.vue')
const StoreBoard = () => import('@/views/StoreBoard.vue')
const StoreDiaryList = () => import('@/views/StoreDiaryList.vue')

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/UserTracking/:locationSid',
    name: 'UserTrackingAct',
    component: UserTrackingAct
  },
  {
    path: '/Result',
    name: 'UserTrackingResult',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: UserTrackingResult
  },
  {
    path: '/CreateStore',
    name: 'CreateStore',
    component: CreateStore
  },
  {
    path: '/StoreBoard/:locationSid',
    name: 'StoreBoard',
    component: StoreBoard
  },
  {
    path: '/StoreDiaryList/:reportSid',
    name: 'StoreDiaryList',
    component: StoreDiaryList
  },
  {
    path :'*',
    component: Home
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
