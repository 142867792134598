<template>
  <div>
    <header id="header">
      <!--/.top-bar-->

      <nav class="navbar navbar-inverse" role="banner">
        <div class="container">
          <div class="navbar-header">
            <span class="navbar-brand">實聯快疫通 Speed Pass</span>
          </div>
        </div>
        <!--/.container-->
      </nav>
      <!--/nav-->

    </header>
    <!--/header-->

    <section id="main-slider" class="no-margin">
      <div class="carousel slide">
        <div class="carousel-inner">

          <div class="item active" style="background-image: url(/images/slides.jpeg)">
            <div class="container">
              <div class="row">
                <div class="col-md-7">
                  <div class="carousel-content">
                    <h1 class="animation animated-item-1">讓店家與場所相關人員迅速掌握正確入場資訊</h1>
                    <div class="animation animated-item-2">
                      「別再讓您的客人停留在門口，只是為了重複填寫實聯制的個人資料」
                    </div>
                    <a class="btn-slide animation animated-item-3" href="/CreateStore">免費申請</a>
                    <a class="btn-slide animation animated-item-3" href="https://www.facebook.com/TWSpeedpass" style="background-color: #385898; border-color: #385898">FB粉絲團</a>
                  </div>
                </div>

              </div>
            </div>
          </div>
          <!--/.item-->

        </div>
        <!--/.carousel-inner-->
      </div>
      <!--/.carousel-->
    </section>
    <!--/#main-slider-->

    <section id="services" class="service-item">
      <div class="container">
        <div class="center fadeInDown">
          <h2>六大好處</h2>
          <p class="lead">讓現場人員直接迅速掌握正確入場資訊。</p>
        </div>

        <div class="row">

          <div class="col-sm-6 col-md-4">
            <div class="media services-wrap fadeInDown">
              <div class="pull-left">
                <img class="img-responsive" src="/images/content_paste_black_48dp.svg">
              </div>
              <div class="media-body">
                <h3 class="media-heading">自動生成可列印公告</h3>
              </div>
            </div>
          </div>

          <div class="col-sm-6 col-md-4">
            <div class="media services-wrap fadeInDown">
              <div class="pull-left">
                <img class="img-responsive" src="/images/lock_black_48dp.svg">
              </div>
              <div class="media-body">
                <h3 class="media-heading">個人資料保密及加密處理</h3>
              </div>
            </div>
          </div>

          <div class="col-sm-6 col-md-4">
            <div class="media services-wrap fadeInDown">
              <div class="pull-left">
                <img class="img-responsive" src="/images/face_black_48dp.svg">
              </div>
              <div class="media-body">
                <h3 class="media-heading">免登入社群不需加好友</h3>
              </div>
            </div>
          </div>

          <div class="col-sm-6 col-md-4">
            <div class="media services-wrap fadeInDown">
              <div class="pull-left">
                <img class="img-responsive" src="/images/drive_file_rename_outline_black_48dp.svg">
              </div>
              <div class="media-body">
                <h3 class="media-heading">不需重複填寫個人資料</h3>
              </div>
            </div>
          </div>

          <div class="col-sm-6 col-md-4">
            <div class="media services-wrap fadeInDown">
              <div class="pull-left">
                <img class="img-responsive" src="/images/security_update_black_48dp.svg">
              </div>
              <div class="media-body">
                <h3 class="media-heading">網頁操作 免安裝app</h3>

              </div>
            </div>
          </div>

          <div class="col-sm-6 col-md-4">
            <div class="media services-wrap fadeInDown">
              <div class="pull-left">
                <img class="img-responsive" src="/images/auto_delete_black_48dp.svg">
              </div>
              <div class="media-body">
                <h3 class="media-heading">資料只保留28天，屆期自動銷毀</h3>
              </div>
            </div>
          </div>

        </div>
        <!--/.row-->
      </div>
      <!--/.container-->
    </section>
    <!--/#services-->

    <section id="testimonial">
      <div class="container">
        <div class="center fadeInDown">
          <h2>簡單操作</h2>
          <p class="lead">完整的資訊有助於門市人員辨識是否為正確實聯制申請<br><span class="text-danger">每日結束後系統安排隔日送出入場資訊連結信給申請單位</span></p>
        </div>
        <div class="testimonial-slider owl-carousel">
          <div class="single-slide">
            <div class="content">
              <img src="/images/p1.png" alt="">
              <p>填寫店家資料，方便快速</p>
            </div>
          </div>
          <div class="single-slide">
            <div class="content">
              <img src="/images/p5.png" alt="">
              <p>免費自動生成可列印公告</p>
            </div>
          </div>
          <div class="single-slide">
            <div class="content">
              <img src="/images/p2.png" alt="">
              <p>只需一次填寫個人資料</p>
            </div>
          </div>
          <div class="single-slide">
            <div class="content">
              <img src="/images/p3.png" alt="">
              <p>自動辨識個人資料快速登記</p>
            </div>
          </div>
          <div class="single-slide">
            <div class="content">
              <img src="/images/p4.png" alt="">
              <p>完成實聯提供店家辨認</p>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section>
      <div class="container">
        <div class="center fadeInDown">
          <h2>落實防疫友善夥伴</h2>
        </div>
        <table class="table table-striped">
          <tbody>
          <tr v-for="(chunk, index) in locationList" :key="index">
            <td v-for="(obj, key) in chunk" :key="key">{{obj}}</td>
          </tr>
          </tbody>
        </table>
        <small>如有疑問，請來信告知</small>
      </div>
    </section>

    <footer id="footer" class="midnight-blue">
      <div class="container">
        <div class="row">
          <div class="col-sm-6">
            &copy; 2014-2021 Young Square Createch LTD.
          </div>
        </div>
      </div>
    </footer>
    <!--/#footer-->
  </div>
</template>

<script>
  import axios from 'axios'
  import _ from 'lodash'

  export default {
    name: 'Home',
    created() {
      this.initialLoad()
    },
    data: function() {
      return {
        locationList: []
      }
    },
    methods: {
      initialLoad: function() {
        axios.get('https://jj7lnyhmk0.execute-api.ap-southeast-1.amazonaws.com/TEST/queryalllocations')
                .then((res) => {
                  const result = JSON.parse(res.data.body)
                  if(result.MESSAGE === 'SUCCESS') {
                    this.locationList = _.chunk(Object.values(result.LOCATION_LIST), 6)
                  }
                })
                .catch((error) => { console.error(error) })
      }
    }

  }
</script>
